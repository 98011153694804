import React from "react"

import Layout from "../components/layout"
import Section from "~components/global/section"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{padding: '200px 0', textAlign: 'center'}}>
      <h3 className="l-sans" style={{paddingBottom: '1rem'}}>404</h3>
      <p>Sorry we can't the page you're looking for.</p>
    </div>
  </Layout>
)

export default NotFoundPage
